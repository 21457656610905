import "@fontsource/dm-sans"
import "@fontsource/dm-sans/500.css"
import "@fontsource/dm-sans/700.css"
import "@fontsource/dm-mono"
import "@fontsource/dm-mono/500.css"
import './src/global.css'
import React from "react"
import Layout from "./src/components/layout"
import { navigate } from 'gatsby';


export const onRouteUpdate = ({ location }) => {
  if (location.pathname === '/entreprise/') {
    navigate('/entreprise/paris-ile-de-france');
  }
};


// export function wrapPageElement({ element, props }) {
//   // props provide same data to Layout as Page element will get
//   // including location, data, etc - you don't need to pass it
//   return <Layout {...props}>{element}</Layout>
// }
